<template>
  <div class="payorder">
    <title-top>订单支付</title-top>
    <div class="line"></div>
    <div class="column flex-center bg-white h210">
      <span class="font32 f*333333">付款</span>
      <span class="font32 f-F62341 mt20">¥{{res.total_amount}}</span>
    </div>
    <span class="row align-center font28 fontweight500 f-333333 h96 ml25">选择支付方式</span>
    <div class="row between align-center bg-white h118">
      <div class="row align-center ml25">
        <img class="imgsize48" src="~images/zfbicon.png" alt />
        <span class="font28 f-333333 ml16">支付宝</span>
      </div>
      <img class="imgsize32 mr25" src="~images/selecticon.png" alt />
    </div>
    <div class="row flex-center" @click="onSure">
      <div class="btn-red mt100" style="background-color:#41ABA7;width:8rem;">确认</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import {orderDetail,balance_payments} from 'network/igou2'
export default {
  name: "PayOrder",
  data() {
    return {
      order_id: this.$route.query.order_id,
      res:{}
    };
  },
  mounted() {
    if (!this.$route.query.order_id) {
      this.$toast.show("参数错误");
      this.$router.back();
      return;
    }
    this._orderDetail(this.$route.query.order_id);
  },
  methods:{
    _orderDetail(id){
      orderDetail(id).then(res=>{
        console.log(res);
        this.res = res;
      })
    },
    _balance_payments(object_type,object_id,payment_password){
      balance_payments(object_type,object_id,payment_password).then(res=>{
        console.log(res);
        if(res.data.state=='paid'){
          //this.$toast.show('支付成功');
          this.$router.replace({
            path:'/zyorderlist',
            query:{order_id:this.order_id}
          })
          this.$toast.show('支付成功');
        }else{
          this.$toast.show('该订单'+res.data.state_zh_cn)
        }
      })
    },
    onSure(){
      this._balance_payments('order',this.order_id,'h11111');
    },
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
</style>
